import { useState } from 'react'
import { Component } from './flags.js'
import { random, get_random_color } from './toolbox.js'

export const Footer = ({ colors }) => {
  const [emoji_ref, set_emoji_ref] = useState(null)
  return (
    <Wrapper id="contact" style={{ background: colors[1] }}>
      <Contact>
        <Quote>
          Don't be shy,
          <br />
          drop me a line!
        </Quote>
        <Details>
          malarmemarie@gmail.com
          <br /> +33 6 08 76 73 32
        </Details>
        <Instagram
          href="https://www.instagram.com/mariemalarme/"
          target="_blank"
        >
          <Arrow rotate180 className="arrow-icon" />
          <Span>Check out my Instagram!</Span>
        </Instagram>
      </Contact>
      <Emojis elemRef={set_emoji_ref}>
        {emojis.map((emoji, index) => {
          const emojis_dimensions = emoji_ref?.getBoundingClientRect()
          return (
            <Emoji
              key={index}
              style={{
                top: random(0, emojis_dimensions?.height - padding) || 0,
                left: random(0, emojis_dimensions?.width - padding) || 0,
              }}
            >
              👋
            </Emoji>
          )
        })}
      </Emojis>
      <Copyright>Marie Malarme © 2021</Copyright>
    </Wrapper>
  )
}

const emojis = [...Array(15).keys()]
const padding = 50

const Wrapper =
  Component.pa50__xxs.pa75.pt50__xxs.pt125.flex.flex_wrap.flex_column__xxs.jc_between__xxs.zi4.sticky.bg_white.section()
const Contact = Component.order2__xxs.w50p.w100p__xxs.sans.fs20.div()
const Quote = Component.fs70.fs50__xxs.mb50.lh60.lh40__xxs.terminal_open.div()
const Details = Component.sans.lh35.div()
const Emojis =
  Component.relative.fs80.w50p.w100p__xxs.mb70__xxs.mb20.min_h150.div()
const Emoji = Component.absolute.span()
const Instagram = Component.fs16__xs.text_dec_none.black.mt40.flex.a()
const Arrow = Component.mr15.w25.w20__xs.h25.h20__xs.div()
const Span = Component.sans.bb.span()
const Copyright = Component.order3__xxs.w100p.mt80.mt30__xxs.footer()
