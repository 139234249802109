import React, { useState, useEffect } from 'react'
import { random } from '../toolbox.js'
import { useExport, useResize } from '../hooks.js'

export const MoveAndDraw = () => {
  const [canvas, set_canvas] = useState(null)
  const [context, set_context] = useState(null)
  const [dimensions, set_dimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    ctx.shadowColor = 'white'
    ctx.shadowBlur = blur_modes[random(0, blur_modes.length)]
    set_context(ctx)

    canvas.addEventListener('click', (e) => {
      ctx.shadowBlur = blur_modes[random(0, blur_modes.length - 1)]
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    })
  }, [canvas])

  useExport(canvas, 'move_and_draw')

  useResize(context, set_dimensions)

  return (
    <canvas
      id="move-and-draw"
      ref={set_canvas}
      width={dimensions.width}
      height={dimensions.height}
      onMouseMove={(e) => context && draw({ e, context })}
      onTouchMove={(e) => context && draw({ e: e.touches[0], context })}
    />
  )
}

let hue1 = 0
let hue2 = 150
let stroke_width = 150
let decreasing = true

const blur_modes = [0, 50]

export const draw = ({ e, context }) => {
  const { pageX, pageY } = e
  const x = e.clientX
  const y = e.clientY
  hue1++
  hue2++
  const sat = x / 6

  if (decreasing && stroke_width > 75) {
    stroke_width = stroke_width - 2
    if (stroke_width === 75) {
      decreasing = false
    }
  } else {
    decreasing = false
    stroke_width = stroke_width + 2
    if (stroke_width === 150) {
      decreasing = true
    }
  }

  const gradient = context.createLinearGradient(x - 35, y - 35, x + 35, y + 35)
  gradient.addColorStop(0, `hsl(${hue1}, ${sat}%, 60%)`)
  gradient.addColorStop(1, `hsl(${hue2}, ${sat}%, 60%)`)
  context.fillStyle = gradient
  context.beginPath()
  context.arc(pageX, pageY, stroke_width, 0, 2 * Math.PI)
  context.fill()
}
