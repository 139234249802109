import { useState, useEffect, useLayoutEffect } from 'react'

export const useResize = (context, set_dimensions) => {
  useEffect(() => {
    const resize_canvas = () => {
      if (!context) return
      const { innerWidth, innerHeight } = window
      const image = context.getImageData(0, 0, innerWidth, innerHeight)
      set_dimensions({ width: innerWidth, height: innerHeight })
      context.putImageData(image, 0, 0)
    }

    window.addEventListener('resize', resize_canvas)
    return () => window.removeEventListener('resize', resize_canvas)
  }, [context, set_dimensions])
}

export const useExport = (canvas, filename) => {
  useEffect(() => {
    const export_canvas_image = (e) => {
      if (e.key !== 's' || !canvas) return
      const link = document.createElement('a')
      const image = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      link.href = image
      link.setAttribute('download', `${filename}.png`)
      link.click()
    }

    window.addEventListener('keydown', export_canvas_image)
    return () => window.removeEventListener('keydown', export_canvas_image)
  }, [canvas, filename])
}

export const useDOMRect = (default_value = new DOMRectReadOnly()) => {
  const [target, ref] = useState()
  const [rect, set_rect] = useState(default_value)

  useLayoutEffect(() => {
    if (!target) return
    set_rect(target.getBoundingClientRect())
    const resizeObserver = new ResizeObserver(() => {
      set_rect(target.getBoundingClientRect())
    })
    resizeObserver.observe(target)
    return () => resizeObserver.disconnect()
  }, [target])

  return [ref, rect, target]
}
