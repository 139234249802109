import { Fragment, useState, useEffect } from 'react'
import { Component } from './flags.js'
import { skills } from './skills.data.js'

export const Skills = () => {
  const hover = useState('Visual identity')
  const [inverted, set_inverted] = useState(false)

  return (
    <Fragment>
      <div id="skills" />
      <Wrapper>
        <Invert none__xxs r20 onClick={() => set_inverted(!inverted)}>
          Invert display
          <Switches ml15>
            <Switch mr5 className="arrow-icon" />
            <Switch ml5 className="arrow-icon" rotate180 />
          </Switches>
        </Invert>
        <Title>Skills</Title>
        <Invert none__xxs l20 onClick={() => set_inverted(!inverted)}>
          <Switches mr15>
            <Switch mr5 className="arrow-icon" />
            <Switch ml5 className="arrow-icon" rotate180 />
          </Switches>
          Invert display
        </Invert>
        <Graph
          type="tech"
          hover={hover}
          inverted={inverted}
          order={inverted ? 2 : 1}
        />
        <Graph
          type="design"
          hover={hover}
          inverted={inverted}
          order={inverted ? 1 : 2}
        />
        {directions.map((direction, i) => (
          <Direction key={i} inverted={inverted} direction={direction} i={i} />
        ))}
      </Wrapper>
    </Fragment>
  )
}

const Graph = ({ type, hover, order, inverted }) => (
  <svg
    width="50%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    style={{ order, overflow: inverted ? 'visible' : 'hidden' }}
  >
    {get_axis().map(({ y1, x2, y2 }, i) => (
      <line key={i} x1={0} y1={y1} x2={x2} y2={y2} stroke={line_color} />
    ))}
    {svg_components.map((component) => {
      const Comp = component
      const skills_type = skills_by_type(type)
      return skills_type.map((skill, i) => (
        <Comp
          key={skill.name}
          inverted={inverted}
          hover={hover}
          skill={skill}
          i={i}
        />
      ))
    })}
  </svg>
)

const Text = ({ skill, i, hover, inverted }) => {
  const coords_set = coords(skill, inverted, i)
  const { center_x, center_y, cx, cy } = coords_set
  const design = skill.area === 'design'
  const [hovered, set_hovered] = hover
  const is_hovered = hovered === skill.name
  const offset = 15 + (is_hovered ? 5 : 0)

  return (
    <text
      x={design ? cx + offset : center_x - cx - offset}
      y={cy + center_y}
      onMouseOver={() => set_hovered(skill.name)}
      fill="black"
      textAnchor={design ? 'start' : 'end'}
      fontSize={is_hovered ? 22 : 12}
      style={{ cursor: 'pointer' }}
      alignmentBaseline="central"
    >
      {skill.name}
    </text>
  )
}

const Skill = ({ skill, i, hover, inverted }) => {
  const coords_set = coords(skill, inverted, i)
  const { center_x, center_y, rx, ry, cx, cy } = coords_set
  const [hovered, set_hovered] = hover
  const is_hovered = hovered === skill.name

  return (
    <g
      onMouseOver={() => set_hovered(skill.name)}
      style={{ cursor: 'pointer' }}
    >
      <ellipse
        cy={center_y}
        cx={center_x}
        ry={ry}
        rx={rx}
        fill="none"
        stroke="black"
      />
      <circle
        r={is_hovered ? 8 : 5}
        cx={skill.area === 'design' ? cx : center_x - cx}
        cy={cy + center_y}
        fill="black"
      />
    </g>
  )
}

const Direction = ({ direction, inverted, i }) => {
  const rotate = `rotate${(i + 1) * 90}`
  return (
    <Compass
      none__xxs={direction === 'design' || direction === 'programmation'}
      t10={!i}
      r10={i === (inverted ? 3 : 1)}
      b10={i === 2}
      l10={i === (inverted ? 1 : 3)}
      w100p={!(i % 2)}
      w_fit_content={i % 2}
      h100p={i % 2}
      wm_v_lr={i % 2}
      rotate180={inverted && i % 2}
    >
      <Icon {...{ [rotate]: true }} className="arrow-icon" />
      {direction}
      <Icon {...{ [rotate]: true }} className="arrow-icon" />
    </Compass>
  )
}

const coords = (skill, inverted, i) => {
  const { amount, area, tangibility } = skill
  const skills_amount = aggregate_amount(skills_by_type(area))
  const percentage = (inverted && amount) || -(amount * 4) + skills_amount

  const { innerWidth, innerHeight } = window
  const padding = innerHeight / 12
  const center_x = area === 'design' ? 0 : innerWidth / 2
  const center_y = innerHeight / 2
  const rx = ((percentage * innerWidth) / total_amount) * (inverted ? 3.5 : 1)
  const ry = center_y - padding
  const cy = (tangibility * ry) / 100
  const cx = rx * Math.sqrt(1 - Math.pow(cy, 2) / Math.pow(ry, 2))

  return { center_x, center_y, rx, ry, cx, cy }
}

const skills_by_type = (type) =>
  skills.filter(({ area }) => area === type).sort((a, b) => a.amount - b.amount)

const aggregate_amount = (skills) =>
  skills.reduce((acc, { amount }) => (acc += amount), 0)

const total_amount = aggregate_amount(skills)

const get_axis = () => {
  const { innerWidth, innerHeight } = window
  const center_y = innerHeight / 2
  const padding = innerHeight / 12
  return [
    { y1: padding, x2: innerWidth, y2: padding },
    { y1: center_y, x2: innerWidth, y2: center_y },
    { y1: 0, x2: 0, y2: innerHeight },
    { y1: innerHeight - padding, x2: innerWidth, y2: innerHeight - padding },
  ]
}

const svg_components = [Skill, Text]
const line_color = 'rgba(0, 0, 0, 0.2)'
const directions = ['digital', 'design', 'tangible', 'programmation']

const Wrapper =
  Component.bg_white.fs13.block.sticky.flex.ai_center.jc_center.t0.w100p.h100vh.zi4.div()
const Title = Component.absolute.terminal_open.fs80.fs60__xs.capitalize.span()
const Invert = Component.fs12.zi4.absolute.t20.c_pointer.flex.ai_center.div()
const Switches = Component.flex.flex_column.div()
const Switch = Component.h15.w15.div()
const Icon = Component.h15.w15.ma10.rotate90.div()
const Compass =
  Component.capitalize.flex.ai_center.jc_center.fs12.absolute.div()
