import React, { useState, useEffect } from 'react'
import { Body, Engine, Render, World, Bodies, Runner } from 'matter-js'
import { Component } from '../flags.js'
import { random, get_random_color } from '../toolbox.js'

// TO DO: resize canvas on window resize:
// change the render > options > width & height + change the walls' positions & dimensions
const colors = [
  get_random_color({ dark: true }),
  get_random_color(),
  get_random_color({ dark: true }),
  get_random_color(),
]

const w = window.innerWidth
const h = window.innerHeight
const up = { x: w / 2, w, h: 100 }
const side = { y: h / 2, w: 100, h }

const walls = {
  ground: { ...up, y: h + 50 },
  ceiling: { ...up, y: -50 },
  left: { ...side, x: -50 },
  right: { ...side, x: w + 50 },
}

export const MoveToGravitate = ({ back_button }) => {
  const [canvas, set_canvas] = useState(null)
  const [engine, set_engine] = useState(null)
  const [clicks_count, set_clicks_count] = useState(0)

  useEffect(() => {
    set_engine(Engine.create())
  }, [])

  useEffect(() => {
    if (!engine) return

    const render = Render.create({
      element: canvas,
      engine,
      options: {
        width: w,
        height: h,
        wireframes: false,
        background: 'transparent',
      },
    })

    engine.world.gravity.y = 5

    Object.values(walls).map(({ x, y, w, h }) => {
      const wall = Bodies.rectangle(x, y, w, h, { isStatic: true, density: 1 })
      World.add(engine.world, wall)
      return World
    })

    const create_shape = (x, y, i) => {
      const color = colors[random(0, 2)]
      const shape = Bodies.circle(x, y, 10, {
        render: { fillStyle: color, id: `circle-${i}` },
      })
      World.add(engine.world, shape)
    }

    const shapes = [...Array(300).keys()]
    shapes.map((i) => create_shape(random(0, w), random(0, h), i))

    Runner.run(engine)
    Render.run(render)
  }, [engine, canvas])

  useEffect(() => {
    if (!engine) return

    const change_gravity = (e) => {
      const event = e && e.type === 'touchmove' ? e.touches[0] : e
      const scale = 4
      const random_pos = random(-scale / 2, scale / 2)
      const x = (e && get_pos(event.clientX, w, scale)) || random_pos
      const y = (e && get_pos(event.clientY, h, scale)) || random_pos
      engine.world.gravity.x = x
      engine.world.gravity.y = y
    }

    document.addEventListener('mousemove', change_gravity)
    document.addEventListener('touchmove', change_gravity)
    return () => {
      document.removeEventListener('mousemove', change_gravity)
      document.removeEventListener('touchmove', change_gravity)
    }
  }, [engine])

  useEffect(() => {
    clicks_count === 6 && set_clicks_count(0)
  }, [clicks_count])

  useEffect(() => {
    const increase_clicks_count = () => set_clicks_count(clicks_count + 1)
    document.addEventListener('click', increase_clicks_count)
    return () => document.removeEventListener('click', increase_clicks_count)
  })

  useEffect(() => {
    if (!engine) return
    const change_circles_size = () => {
      const circles = engine.world.bodies.filter(
        (b) => b.label === 'Circle Body',
      )
      const scale = clicks_count < 3 ? 2 : 0.5
      circles.map((circle) => {
        Body.scale(circle, scale, scale)
        return Body
      })
    }

    document.addEventListener('click', change_circles_size)
    return () => document.removeEventListener('click', change_circles_size)
  }, [engine, clicks_count])

  useEffect(() => {
    if (!back_button || !engine) return
    const clear_world = () => World.clear(engine.world)
    back_button.addEventListener('click', clear_world)
    return () => back_button.removeEventListener('click', clear_world)
  }, [back_button, engine])

  return (
    <Canvas
      id="gravity"
      elemRef={set_canvas}
      style={{ background: colors[3] }}
    />
  )
}

const get_pos = (pos, max, scale) => (pos / max) * scale - scale / 2
const Canvas = Component.w100p.h100vh.fixed.div()
