import React, { useEffect, useState } from 'react'
import { Component, Div } from '../flags.js'

export const ClickToSwitch = ({ immersive_view }) => {
  const [mode, set_mode] = useState(modes[1])
  const [repeat, set_repeat] = useState(1)
  const [color, set_color] = useState({
    hue1: 0,
    hue2: 180,
  })

  const { hue1, hue2 } = color

  useEffect(() => {
    const update_color = (e) => {
      const { clientX, clientY } = e.type === 'touchmove' ? e.touches[0] : e
      set_color({
        ...color,
        hue1: (clientX * 360) / window.innerWidth,
        hue2: (clientY * 360) / window.innerHeight,
      })
    }
    window.addEventListener('mousemove', update_color)
    window.addEventListener('touchmove', update_color)
    return () => {
      window.removeEventListener('mousemove', update_color)
      window.removeEventListener('touchmove', update_color)
    }
  })

  const rotation =
    (mode === 'horizontal' && '0deg') ||
    (mode === 'vertical' && '90deg') ||
    mode

  return (
    <Wrapper
      style={{
        backgroundImage: `linear-gradient(
        ${rotation},
        hsl(${hue1}, 50%, 70%) 0%,
        hsl(${hue2}, 50%, 70%) 25%,
        hsl(${hue1 - 180}, 50%, 70%) 48%,
        hsl(${hue1 - 180}, 50%, 70%) 52%,
        hsl(${hue2}, 50%, 70%) 75%,
        hsl(${hue1}, 50%, 70%) 100%)`,
        backgroundSize: `${
          window.innerWidth / (mode === 'vertical' ? repeat : 1)
        }px ${window.innerHeight / repeat}px`,
      }}
    >
      {!immersive_view && (
        <Modes
          repeat={repeat}
          set_repeat={set_repeat}
          mode={mode}
          set_mode={set_mode}
        />
      )}
    </Wrapper>
  )
}

const Modes = ({ repeat, set_repeat, mode, set_mode }) => (
  <Div w100p ph15__xs ph100 flex jc_between fixed b200 b50__xs>
    <Mode
      disabled={repeat === 1}
      caption="decrease"
      onClick={() => repeat > 1 && set_repeat(repeat - 1)}
    />
    {modes.map((m) => (
      <Mode
        key={m}
        caption={m}
        selected={m === mode}
        onClick={() => set_mode(m)}
      />
    ))}
    <Mode caption="increase" onClick={() => set_repeat(repeat + 1)} />
  </Div>
)

const Mode = ({ caption, onClick, selected, disabled }) => (
  <Control o30={disabled} onClick={onClick}>
    <Dot />
    <Div fs9__xs pb5 bb={selected}>
      {caption}
    </Div>
  </Control>
)

const Wrapper = Component.w100p.h100vh.flex.ai_center.jc_center.div()
const Control =
  Component.pointer.c_pointer.flex.flex_column.ai_center.text_center.w150.div()
const Dot = Component.w5.h5.mb15.b_rad50p.bg_black.div()
const modes = ['vertical', 'horizontal', '35deg']
