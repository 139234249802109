import { Fragment, useState } from 'react'
import { Component, Div } from './flags.js'
import { random, format, get_random_color } from './toolbox.js'
import { themes } from './Home.js'
import { projects as data } from './projects.data.js'
import { ProjectsMap } from './projects-map.js'

export const Projects = ({ project, set_project, ...props }) => {
  const { theme, set_theme, colors, set_colors, projects, set_projects } = props

  const [grid, set_grid] = useState(grids[0])

  const search_params = new URLSearchParams(window.location.search)
  const queried_filter = search_params.get('filter')
  const [selected_filter, set_selected_filter] = useState(queried_filter)

  const filtered_projects = selected_filter
    ? projects.filter((project) => project.tags.includes(selected_filter))
    : projects

  return (
    <Fragment>
      <Controls
        grid={grid}
        set_grid={set_grid}
        theme={theme}
        set_theme={set_theme}
        set_colors={set_colors}
        set_projects={set_projects}
        selected_filter={selected_filter}
        set_selected_filter={set_selected_filter}
      />

      <ProjectsMap
        grid={grid}
        theme={theme}
        colors={colors}
        set_project={set_project}
        projects={filtered_projects}
        set_projects={set_projects}
      />
    </Fragment>
  )
}

const Controls = (props) => {
  const { grid, set_grid, theme, set_theme, set_colors, set_projects } = props
  const { selected_filter, set_selected_filter } = props

  return (
    <Bar relative>
      <Settings>
        <Div sans fs15 mr15 mt2>
          Settings:
        </Div>

        <Setting name="colors" on_click={() => set_colors(get_colors)} />
        <Setting
          name="theme"
          on_click={() => {
            const current_theme_index = themes.indexOf(theme)
            const is_last_index = current_theme_index === themes.length - 1
            const next_theme_index = is_last_index ? 0 : current_theme_index + 1
            set_theme(themes[next_theme_index])
          }}
        />
        <Setting
          name="grid"
          on_click={() => {
            const current_grid_index = grids.indexOf(grid)
            const is_last_index = current_grid_index === grids.length - 1
            const next_grid_index = is_last_index ? 0 : current_grid_index + 1
            set_grid(grids[next_grid_index])
            window.scrollTo(0, 0)
          }}
        />

        <Setting
          none__xs
          name="coordinates"
          disabled={grid !== 'random'}
          on_click={() => set_projects(get_projects)}
        />

        <Filters
          selected_filter={selected_filter}
          set_selected_filter={set_selected_filter}
        />
      </Settings>
    </Bar>
  )
}

const Setting = ({ name, disabled, on_click, ...props }) => {
  const [is_hovered, set_is_hovered] = useState(false)

  return (
    <Div flex flex_column ai_center {...props}>
      <Icon
        o30={disabled}
        c_pointer={!disabled}
        className={`${name}-icon`}
        onMouseEnter={(event) => !disabled && set_is_hovered(true)}
        onMouseLeave={() => set_is_hovered(false)}
        onClick={() => !disabled && on_click()}
      />

      {is_hovered && <Instruction>Switch {name}</Instruction>}
    </Div>
  )
}

const Filters = ({ selected_filter, set_selected_filter }) => {
  const url = new URL(window.location.href)
  const search_params = url.searchParams
  const queried_filter = search_params.get('filter')

  const set_search_params_filter = (filter) => {
    search_params.set('filter', filter)
    url.search = search_params.toString()
    set_selected_filter(filter)
    window.history.pushState({}, '', url)
    window.scrollTo(0, 0)
  }

  return (
    <Div ml10 flex ofx_auto>
      <Filter
        line_through={selected_filter}
        style={{ padding: '1px 9px' }}
        onClick={() => {
          search_params.delete('filter')
          set_selected_filter(null)
          window.history.pushState({}, '', url)
        }}
      >
        All
      </Filter>
      {filters.map((filter) => {
        return (
          <Filter
            line_through={filter !== queried_filter}
            onClick={() => set_search_params_filter(filter)}
            key={filter}
            style={{ padding: '1px 9px' }}
          >
            {filter}
          </Filter>
        )
      })}
    </Div>
  )
}

export const get_projects = () => {
  const { innerWidth, innerHeight } = window
  const thumbnail_size = get_thumbnail_size()
  const mid_y = innerHeight
  const mid_x = innerWidth / 2

  return data.map((project, project_index) => {
    const is_on_top = random(0, 1)
    const is_on_left = random(0, 1)
    const background_color_index = random(0, 1)

    return {
      ...project,
      display: {
        title: {
          top: is_on_top ? random(50, mid_y) : 'auto',
          bottom: is_on_top ? 'auto' : random(0, mid_y - 100),
          left: is_on_left ? random(0, mid_x) : 'auto',
          right: is_on_left ? 'auto' : random(0, mid_x),
          colors: {
            background: background_color_index,
            text: background_color_index ? 0 : 1,
          },
        },
        thumbnail: {
          top: random(0, innerHeight - thumbnail_size),
          left: random(0, innerWidth - thumbnail_size),
        },
      },
    }
  })
}

export const get_colors = () =>
  [...Array(2).keys()].map((index) =>
    get_random_color({
      dark: index,
      h: index ? random(-20, 200) : random(160, 380),
    }),
  )

export const find_project = (projects) => {
  const project_name = window.location.pathname.slice(1)
  return projects.find(({ name }) => format(name) === project_name)
}

export const get_thumbnail_size = () => window.innerHeight / 2.75

const grids = ['inline', 'random', 'block']
const filters = ['code', 'electronics', 'design', 'demo']

const Bar = Component.fixed.zi2.t20.ml25.div()
const Settings = Component.ofx_auto__xxs.flex.ai_center.div()
const Icon = Component.c_pointer.w20.h20.mr5.div()
const Filter = Component.bg_glass.c_pointer.fs11.mr10.ba.b_rad20.div()
const Instruction = Component.grey9.fs12.mono.absolute.t30.t25__xxs.div()
