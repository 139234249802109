import { useState, useEffect } from 'react'
import { Component } from './flags.js'

export const Canvas = ({ height, context, set_context }) => {
  const [canvas, set_canvas] = useState(null)
  const [dimensions, set_dimensions] = useState({
    width: window.innerWidth,
    height,
  })

  useEffect(() => {
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    set_context(ctx)
  }, [canvas, set_context])

  useEffect(() => {
    if (!context) return
    document.addEventListener('mousemove', (e) => draw({ e, context }))
    return () =>
      document.addEventListener('mousemove', (e) => draw({ e, context }))
  }, [context])

  useEffect(() => {
    const set_canvas_size = () => {
      set_dimensions({ width: window.innerWidth, height })
    }

    set_canvas_size()

    const resizeObserver = new ResizeObserver(set_canvas_size)
    resizeObserver.observe(document.body)

    window.addEventListener('resize', set_canvas_size)
    return () => window.removeEventListener('resize', set_canvas_size)
  }, [height])

  return (
    <CanvasWrapper
      width={dimensions.width}
      height={dimensions.height}
      elemRef={set_canvas}
    ></CanvasWrapper>
  )
}

let strokeWidth = 100
let decreasing = true

const draw = ({ e, context }) => {
  const { pageX, pageY } = e
  const x = e.clientX
  const y = e.clientY + document.documentElement.scrollTop

  if (decreasing && strokeWidth > 45) {
    strokeWidth = strokeWidth - 2
    if (strokeWidth === 45) {
      decreasing = false
    }
  } else {
    decreasing = false
    strokeWidth = strokeWidth + 2
    if (strokeWidth === 100) {
      decreasing = true
    }
  }

  const gradient = context.createLinearGradient(x - 35, y - 35, x + 35, y + 35)
  gradient.addColorStop(0, 'black')
  gradient.addColorStop(1, 'white')
  context.fillStyle = gradient
  context.beginPath()
  context.arc(pageX, pageY, strokeWidth, 0, 2 * Math.PI)
  context.fill()
}

const CanvasWrapper = Component.f_grey100.absolute.t0.l0.w100p.o15.canvas()

// animation to make the drawing fade out
// useEffect(() => {
//   if (!context) return
//   const fade_out = () => {
//     const alpha = 0.03
//     context.fillStyle = `rgba(255, 255, 255, ${alpha})`
//     context.fillRect(0, 0, window.innerWidth, height)
//     setTimeout(fade_out, 10)
//     // requestAnimationFrame(fade_out)
//   }
//   fade_out()
// }, [context])
