import React, { useState, useEffect } from 'react'
import { useResize, useExport } from '../hooks.js'
import { random, get_random_color } from '../toolbox.js'

export const AudioDrawing = () => {
  const [canvas, set_canvas] = useState(null)
  const [context, set_context] = useState(null)

  const [dimensions, set_dimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    set_context(ctx)
  }, [canvas])

  useEffect(() => {
    if (!context) return
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => handle_stream(stream, context))
  }, [context])

  useResize(context, set_dimensions)
  useExport(canvas, 'speak_to_draw')

  return (
    <canvas
      style={{ background: colors[2] }}
      width={dimensions.width}
      height={dimensions.height}
      ref={set_canvas}
    />
  )
}

const draw_frequency_bar_y = (frequency_value, ctx) => {
  const max_x = window.innerWidth
  const max_y = window.innerHeight
  const width_third = max_x / 3.5

  const width_value = (frequency_value + increaser) * 3 - increaser
  const height_value = (width_value - 125) / 20
  const is_width_negative = width_value <= 0
  const is_height_negative = height_value <= 0
  if (is_width_negative || is_height_negative) return
  const bar_width = ((width_value - 150) / max_x) * 6000
  const bar_height = height_value * 15 + 1

  const x = max_x / 2 - bar_width / 2
  const last_bar = bars[bars.length - 1]
  const y = bars.length > 0 ? last_bar.height + last_bar.y - 1 : 0
  bars.push({ x, y, width: bar_width, height: bar_height })

  const page_filled = y_count > max_y

  if (page_filled) {
    bars = bars
      .map((bar) => ({
        ...bar,
        y: bar.y - bars[bars.length - 1].height + 1,
      }))
      .filter((bar) => bar.y + bar.height > 0)
    ctx.clearRect(0, 0, max_x, max_y)
  } else {
    y_count += bar_height
  }

  const waves_coords = [
    [0, 0, 0],
    [-width_third, -(width_third - 100), -width_third],
    [width_third - 100, width_third, width_third],
  ]

  bars.forEach((bar) => {
    waves_coords.forEach((coords) => {
      create_wave(bar, coords, ctx, height_value)
    })
  })
}

const create_wave = (bar, coords, ctx, hue) => {
  const { x, y, width, height } = bar
  const [x1, x2, x3] = coords

  const gradient = ctx.createLinearGradient(x + x1, y, x + width + x2, y)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.25, colors[1])
  gradient.addColorStop(0.5, colors[2])
  gradient.addColorStop(0.75, colors[1])
  gradient.addColorStop(1, colors[0])

  ctx.fillStyle = gradient
  ctx.fillRect(x + x3, y, width, height)
}

const handle_stream = (stream, ctx) => {
  context = new AudioContext()
  analyser = context.createAnalyser()
  microphone = context.createMediaStreamSource(stream)

  microphone.connect(analyser)
  analyser.fftSize = 32

  const buffer_length = analyser.frequencyBinCount
  const frequency_data = new Float32Array(buffer_length)

  setInterval(() => {
    analyser.getFloatFrequencyData(frequency_data)
    const frequency_value = Math.max(...frequency_data)
    draw_frequency_bar_y(frequency_value, ctx)
  }, 30)
}

const colors = [
  get_random_color({ h: random(0, 120), dark: true }),
  get_random_color({ h: random(120, 240), dark: true }),
  get_random_color({ h: random(240, 360) }),
]

let context
let microphone
let analyser

let bars = []
let y_count = 0
let increaser = 150
