import React, { useEffect, useState } from 'react'
import { Component, Div } from '../flags.js'

const faces = [
  { face: 'cover', rotation: 0, axis: 'Y' },
  { face: 'spine', rotation: -90, axis: 'Y' },
  { face: 'back', rotation: 180, axis: 'Y' },
  { face: 'top', rotation: 90, axis: 'X' },
  { face: 'side', rotation: 90, axis: 'Y' },
  { face: 'bottom', rotation: -90, axis: 'X' },
]

const styles = ['solid', 'translucid', 'linear', 'radial']

export const Rotate3d = () => {
  const [cube, set_cube] = useState(null)
  const [page, set_page] = useState(null)
  const [style, set_style] = useState(styles[0])

  useEffect(() => {
    if (!cube || !page) return
    const rotate = (e) => {
      const { innerWidth, innerHeight } = window
      const { clientX, clientY } = e.type === 'touchmove' ? e.touches[0] : e
      const midX = clientX - innerWidth / 2
      const midY = clientY - innerHeight / 2
      cube.style.transform = `rotateX(${midY / 2}deg) rotateY(${midX}deg)`
      page.style.filter = `hue-rotate(${(clientX * 360) / innerHeight}deg)`
    }

    document.addEventListener('mousemove', rotate)
    document.addEventListener('touchmove', rotate)
    return () => {
      document.removeEventListener('mousemove', rotate)
      document.removeEventListener('touchmove', rotate)
    }
  }, [cube, page])

  useEffect(() => {
    const changeStyle = () => {
      const current = styles.indexOf(style)
      const last = current === styles.length - 1
      const next = last ? 0 : current + 1
      set_style(styles[next])
    }

    document.addEventListener('click', changeStyle)
    return () => document.removeEventListener('click', changeStyle)
  }, [style])

  return (
    <Div flex ai_center jc_center w100p h100vh elemRef={set_page}>
      <Cube
        elemRef={set_cube}
        style={{
          transformStyle: 'preserve-3d',
        }}
      >
        {faces.map(({ face, rotation, axis }, i) => (
          <Face
            id={face}
            key={face}
            style={{
              transform: `rotate${axis}(${rotation}deg) translateZ(100px)`,
              backgroundImage: getBackground(style, face, i),
              animation:
                style === 'translucid'
                  ? 'bg-size 1s alternate linear infinite'
                  : 'bg-position 1.5s linear infinite',
            }}
          />
        ))}
      </Cube>
    </Div>
  )
}

const hsl = (hue) => `hsl(${hue}, 70%, 65%)`
const gradient = { step1: hsl(100), step2: hsl(250), step3: hsl(170) }
const { step1, step2, step3 } = gradient

const getBackground = (style, face, i) => {
  const solid = style === 'solid'
  const translucid = style === 'translucid'
  const linear = style === 'linear'
  const radial = style === 'radial'

  if (linear) {
    return (
      ((face === 'bottom' || face === 'top') &&
        'linear-gradient(transparent, transparent)') ||
      `linear-gradient(${step1}, ${step2}, ${step3}, ${step2}, ${step1})`
    )
  }

  if (translucid) {
    return `radial-gradient(${step1}, transparent)`
  }

  if (solid) {
    return `linear-gradient(${step1}, ${step2}, ${step3}`
  }

  if (radial) {
    return `radial-gradient(hsl(${
      (i * 360) / faces.length
    }, 70%, 65%), transparent)`
  }
}

const Cube = Component.absolute.noEvents.div()
const Face = Component.absolute.t0.l0.pa20.w200.h200.div()
