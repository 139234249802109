import { useState, useEffect, Fragment } from 'react'
import { events } from './events.data.js'
import { Component, Div } from './flags.js'

export const Curriculum = ({ colors }) => {
  const [selected_event, set_selected_event] = useState(events[0])
  const [scrolled, set_scrolled] = useState(0)
  const [carousel, set_carousel] = useState(null)
  const [top, set_top] = useState(0)

  useScroll(carousel, set_scrolled, top)
  useTop(carousel, top, set_top)

  const angle = (scrolled <= revolution && (scrolled * 360) / revolution) || 360
  const conic_gradient = `conic-gradient(from ${angle}deg, ${colors[0]}, ${colors[1]})`

  return (
    <Fragment>
      <Banners id="curriculum">
        {banners.map((index) => (
          <Banner key={index}>Curriculum —</Banner>
        ))}
      </Banners>
      <Container elemRef={set_carousel}>
        <ConicGradient style={{ background: conic_gradient }} />
        <Header t50>{selected_event.description}</Header>
        <Header b50>
          at{' '}
          <Link href={selected_event.link} target="_blank">
            {selected_event.place}
          </Link>
        </Header>
        <MilestonesLines>
          <MilestonesLine selected_event={selected_event} left />
          <MilestonesLine selected_event={selected_event} right />
        </MilestonesLines>
        <Carousel
          key="carousel"
          selected_event={selected_event}
          set_selected_event={set_selected_event}
          scrolled={scrolled}
        />
      </Container>
      <Div style={{ height: revolution }} />
    </Fragment>
  )
}

const Carousel = ({ scrolled, selected_event, set_selected_event }) => {
  const rotation = -(scrolled / rotation_inc)
  const transform = `rotateX(${rotation}deg)`

  return (
    <History style={{ perspective: '500px' }}>
      <Slides style={{ transformStyle: 'preserve-3d', transform }}>
        {events.map((event, i) => (
          <Slide
            key={`carousel-event-${i}`}
            event={event}
            length={events.length}
            rx_carousel={Math.abs(rotation)}
            selected_event={selected_event}
            set_selected_event={set_selected_event}
            i={i}
          />
        ))}
      </Slides>
    </History>
  )
}

const Slide = (props) => {
  const { selected_event, set_selected_event, event } = props
  const { i, length, rx_carousel } = props
  const rx = (360 * i) / length
  const tz = Math.floor(slide_height / 2 / Math.tan(Math.PI / length))
  const in_view_min = Math.abs(rx_carousel > rx - angle_offset)
  const in_view_max = Math.abs(rx_carousel < rx + angle_offset)
  const in_view = in_view_min && in_view_max

  useEffect(() => {
    // update the selected event when the slide comes into view
    if (in_view && selected_event !== event) {
      set_selected_event(event)
    }
  })

  return (
    <Wrapper style={{ transform: `rotateX(${rx}deg) translateZ(${tz}px)` }}>
      <SlideEvent
        grey9={in_view}
        grey1={!in_view}
        style={{
          fontSize: '1.5vw',
          lineHeight: '1.1vw',
          transform: 'scale(2.5, 7)',
        }}
      >
        {event.title}
      </SlideEvent>
    </Wrapper>
  )
}

const MilestonesLine = ({ selected_event, left, right }) => (
  <Div pl10__xs={left} pr10__xs={right} pl40={left} pr40={right} w50p zi2>
    {events.map((event) => {
      const height = `${100 / events.length}%`
      const is_selected_event = selected_event === event
      return (
        <Milestone key={event.id} jc_flex_end={right} style={{ height }}>
          <MilestoneGraph order2={right}>
            <MilestoneDot
              o20={!is_selected_event}
              w15={is_selected_event}
              h15={is_selected_event}
              w10__xs={is_selected_event}
              h10__xs={is_selected_event}
            />
            <MilestoneLine style={{ height }} />
          </MilestoneGraph>
          <MilestoneEvent
            fs21__xs={is_selected_event}
            fs28={is_selected_event}
            o100={is_selected_event}
          >
            <Div mh10 order2={left} fs13={is_selected_event}>
              {(left && event.day) || (right && event.country)}
            </Div>
            {(left && event.year) || (right && event.city)}
          </MilestoneEvent>
        </Milestone>
      )
    })}
  </Div>
)

const useScroll = (carousel, set_scrolled, top) => {
  useEffect(() => {
    const update_scrolled = () => {
      if (!(enters_view(carousel, top) && !leaves_view(carousel, top))) return
      set_scrolled(document.documentElement.scrollTop - top)
    }
    document.addEventListener('scroll', update_scrolled)
    return () => document.removeEventListener('scroll', update_scrolled)
  }, [carousel, set_scrolled, top])
}

const useTop = (carousel, top, set_top) => {
  useEffect(() => {
    if (!carousel) return
    set_top(carousel.offsetTop)
  }, [carousel, set_top])
}

const enters_view = (carousel, top) => {
  if (!carousel || !top) return
  return document.documentElement.scrollTop - top >= 0
}

const leaves_view = (carousel, top) => {
  if (!carousel || !top) return
  const scrolled = document.documentElement.scrollTop - top
  return scrolled >= revolution + window.innerHeight
}

const Banners =
  Component.bt.pt20.pb10.uppercase.fs90.fs40__xxs.sans.ls2.zi3.sticky.t0.w100p.bg_glass.fs13.flex.jc_center.ws_nowrap.of_hidden.div()
const Banner = Component.sans.pv10.ph20.div()
const History = Component.w100p.h100vh.relative.div()
const Slides = Component.w100p.flex.ai_center.jc_center.h100p.absolute.div()
const Wrapper =
  Component.w100p.absolute.fs80.flex.flex_column.ai_center.jc_center.div()
const Container =
  Component.sticky.t0.of_hidden.zi3.bg_white.w100p.flex.jc_center.div()
const ConicGradient = Component.absolute.w100p.h100vh.div()
const SlideEvent =
  Component.terminal.text_center.fs22.b_rad100p.pa30.bw3.w60p.flex.flex_column.ai_center.div()
const Header =
  Component.zi3.ba.fs14.fs11__xxs.sans.uppercase.ls2.ph15.pv7.b_rad100.absolute.div()
const Link = Component.black.sans.bb.text_dec_none.a()
const MilestonesLines = Component.absolute.h100vh.w100p.flex.jc_between.div()
const Milestone = Component.flex.ai_center.div()
const MilestoneGraph = Component.mh10.w15.flex.ai_center.jc_center.div()
const MilestoneLine = Component.o10.absolute.w1.h100p.bg_black.div()
const MilestoneDot = Component.w5.h5.b_rad50p.bg_black.div()
const MilestoneEvent = Component.fs13.sans.flex.ai_baseline.o20.div()

// calculate the slides' height according to the viewport width:
// use the logarithm of the width to restrict the speed of growth of the height;
// after experiment, setting an arbitrary value at 1300 which fits the design
const slide_height = 1300 / Math.log(window.innerWidth)
const rotation_inc = 10
const angle_offset = 360 / events.length / 2
const revolution = (360 - 360 / events.length) * rotation_inc
const banners = [...Array(21).keys()]
