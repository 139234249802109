import { Fragment, useState, useEffect } from 'react'
import { Projects, find_project, get_projects } from './projects.js'
import { Intro } from './intro.js'
import { Modal } from './modal.js'
import { About } from './about.js'
import { Curriculum } from './curriculum.js'
import { Skills } from './skills.js'
import { Footer } from './footer.js'

const Home = () => {
  const [projects, set_projects] = useState(get_projects)
  const [project, set_project] = useState(find_project(projects))
  const [colors, set_colors] = useState(base_colors)
  const [theme, set_theme] = useState(themes[0])

  useEffect(() => {
    document.body.style.overflow = project ? 'hidden' : 'auto'
  }, [project])

  return (
    <Fragment>
      <Intro theme={theme} colors={colors} set_colors={set_colors} />
      <Modal project={project} set_project={set_project} />
      <Projects
        theme={theme}
        set_theme={set_theme}
        colors={colors}
        set_colors={set_colors}
        project={project}
        set_project={set_project}
        projects={projects}
        set_projects={set_projects}
      />
      <About colors={colors} />
      <Curriculum colors={colors} />
      <Skills />
      <Footer colors={colors} />
    </Fragment>
  )
}

export const themes = ['light', 'colors', 'plain']
const base_colors = ['rgb(208, 138, 255)', 'hsla(141, 96%, 40%, 1)']

export default Home
