import { useState, useEffect } from 'react'
import { Component } from '../flags.js'
import { random } from '../toolbox.js'
import { useExport } from '../hooks.js'
import klimt from '../images/drop_to_draw/klimt.jpg'
import ColorThief from 'colorthief'

export const DropToDraw = ({ immersive_view }) => {
  const [image_src, set_image_src] = useState(klimt)
  const [canvas, set_canvas] = useState(null)
  const [context, set_context] = useState(null)
  const [dimensions, set_dimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    set_context(ctx)
  }, [canvas])

  useEffect(() => {
    if (!context) return
    draw({ image_src, canvas, context })
  }, [context, image_src, canvas])

  useResize(canvas, context, set_dimensions, image_src)
  useExport(canvas, 'drop_to_draw')

  return (
    <DropZone
      onDragEnter={prev_default}
      onDragOver={prev_default}
      onDrop={(event) => {
        event.preventDefault()

        const file = event.dataTransfer.files[0]
        if (file) {
          const reader = new FileReader()
          reader.onload = (event) => set_image_src(event.target.result)
          reader.readAsDataURL(file)
          return
        }
      }}
    >
      <Canvas
        width={dimensions.width}
        height={dimensions.height}
        elemRef={set_canvas}
      />
      {image_src && !immersive_view && <ImageTag src={image_src} />}
    </DropZone>
  )
}

const useResize = (canvas, context, set_dimensions, image_src) => {
  useEffect(() => {
    const resize_canvas = () => {
      if (!context) return
      const { innerWidth, innerHeight } = window
      set_dimensions({ width: innerWidth, height: innerHeight })
      clearTimeout(timeout)
      timeout = setTimeout(() => draw({ image_src, canvas, context }), 100)
    }

    window.addEventListener('resize', resize_canvas)
    return () => window.removeEventListener('resize', resize_canvas)
  }, [context, set_dimensions, canvas, image_src])
}

const draw_circles_lines = ({
  image,
  canvas,
  context,
  circles_amount_mult = 3,
  background = false,
  gradient = true,
  inc_mult = { x: 5, y: 5 },
  pos_mult = { x: 3, y: 3 },
  radius = 7.5,
  start_pos = {
    x: () => random(0, window.innerWidth),
    y: () => random(0, window.innerHeight),
  },
}) => {
  const color_thief = new ColorThief()
  const palette = color_thief.getPalette(image, 15)
  context.clearRect(0, 0, window.innerWidth, window.innerHeight)

  if (background) {
    const [r, g, b] = color_thief.getColor(image)
    canvas.style.background = `rgb(${r}, ${g}, ${b})`
  }

  palette.reverse().forEach((color, i) => {
    const [r, g, b] = color
    const circles_amount = i * circles_amount_mult
    const dir_x = random(0, 1)
    const dir_y = random(0, 1)

    let posX = start_pos.x()
    let posY = start_pos.y()
    let inc_x = 0
    let inc_y = 0

    for (let i = 0; i < circles_amount; i++) {
      const x = dir_x
        ? posX + inc_x + i * pos_mult.x
        : posX - inc_x - i * pos_mult.x
      const y = dir_y
        ? posY + inc_y + i * pos_mult.y
        : posY - inc_y - i * pos_mult.y

      const circle = new Path2D()
      circle.arc(x, y, radius, 0, 2 * Math.PI)

      if (gradient) {
        const grad = context.createRadialGradient(x, y, 0, x, y, radius)
        grad.addColorStop(0, `rgba(${r}, ${g}, ${b}, 1)`)
        grad.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`)
        context.fillStyle = grad
      } else {
        context.fillStyle = `rgba(${r}, ${g}, ${b}, 1)`
      }

      context.fill(circle)

      inc_x = inc_x + random(-radius * inc_mult.x, radius * inc_mult.x)
      inc_y = inc_y + random(-radius * inc_mult.y, radius * inc_mult.y)
    }
  })
}

const draw = ({ image_src, canvas, context }) => {
  const image = new Image()
  image.onload = () => {
    draw_circles_lines({
      image,
      canvas,
      context,
      radius: 4,
      gradient: false,
      background: true,
      circles_amount_mult: 100,
    })
  }
  image.src = image_src
}

const prev_default = (e) => e.preventDefault()

let timeout
const DropZone = Component.w100p.h100vh.flex.jc_center.ai_center.div()
const Canvas = Component.absolute.canvas()
const ImageTag = Component.zi0.cover.w250.h250.img()
