import React, { useState, useEffect } from 'react'
import { random, get_random_color } from '../toolbox.js'
import { useExport, useResize } from '../hooks.js'

export const SpaceToDraw = () => {
  const [canvas, setCanvas] = useState(null)
  const [context, setContext] = useState(null)
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    setContext(ctx)
  }, [canvas])

  useResize(context, setDimensions)
  useKeyEvents(context, canvas, setDimensions, dimensions)

  return (
    <canvas
      width={dimensions.width}
      height={dimensions.height}
      ref={setCanvas}
    />
  )
}

const useKeyEvents = (context, canvas, setDimensions, dimensions) => {
  useEffect(() => {
    if (!context) return
    window.addEventListener('keyup', keyup)
    return () => window.removeEventListener('keyup', keyup)
  }, [context])

  useEffect(() => {
    if (!context) return
    window.addEventListener('keydown', (e) => keydown(e, context))
    return () =>
      window.removeEventListener('keydown', (e) => keydown(e, context))
  }, [context])

  useEffect(() => {
    if (!context) return
    window.addEventListener('keydown', (e) =>
      draw(e, canvas, context, dimensions),
    )
    return () =>
      window.removeEventListener('keydown', (e) =>
        draw(e, canvas, context, dimensions),
      )
  }, [context, canvas, dimensions])

  useExport(canvas, 'space_to_draw')
}

const draw_shape = (inc_x, inc_y, pos_x, pos_y, color, radius, ctx) => {
  const new_shape = new Path2D()
  new_shape.arc(pos_x + inc_x, pos_y + inc_y, radius, 0, 2 * Math.PI)
  ctx.fillStyle = color
  ctx.fill(new_shape)
}

const draw_line = (context, shapes_amount) => {
  reset()
  for (let i = 0; i < shapes_amount; i++) {
    draw_shape(inc_x, inc_y, pos_x, pos_y, color, radius, context)
    inc_x = inc_x + random(0, radius)
    inc_y = inc_y + random(-radius, radius)
  }
}

const draw = (e, canvas, context, dimensions) => {
  const { width, height } = dimensions
  if (e.key === 'Enter') for (let i = 0; i < 5; i++) draw_line(context, 100)
  if (e.key === 'c') context.clearRect(0, 0, width, height)
}

const keydown = (e, context) => {
  if (e.keyCode !== 32) return
  draw_shape(inc_x, inc_y, pos_x, pos_y, color, radius, context)
  inc_x = inc_x + random(0, radius)
  inc_y = inc_y + random(-radius, radius)
}

const keyup = (e) => e.keyCode === 32 && reset()

const reset = () => {
  inc_x = 0
  inc_y = 0
  pos_x = random(0, window.innerWidth / 2)
  pos_y = random(0, window.innerHeight)
  color = get_random_color({ s: 100, l: random(20, 70) })
  radius = random(10, 40)
}

let inc_x = 0
let inc_y = 0
let pos_x = random(0, window.innerWidth / 2)
let pos_y = random(0, window.innerHeight)
let color = get_random_color({ s: 100, l: random(20, 70) })
let radius = random(10, 40)
