import { atomizify, flagify } from 'atomizify'
// import { display_classes } from 'atomizify/doc'

// display_classes()
atomizify({
  custom_classes: {
    w1: 'width: 1px',
    h1: 'height: 1px',
    w15vw: 'width: 15vw',
    max_w30p: 'max-width: 30%',
    w50vw: 'width: 50vw',
    h50vh: 'height: 50vh',
    h90vh: 'height: 90vh',
    max_h50vh: 'max-height: 50vh',
    max_h95vh: 'max-height: 95vh',
    cover: 'object-fit: cover',
    lh35: 'line-height: 35px',
    lh40: 'line-height: 40px',
    lh45: 'line-height: 45px',
    lh60: 'line-height: 60px',
    lh65: 'line-height: 65px',
    lh90: 'line-height: 90px',
    lh100: 'line-height: 100px',
    fs35: 'font-size: 35px',
    fs44: 'font-size: 44px',
    fs55: 'font-size: 55px',
    fs3vw: 'font-size: max(25px, 2.5vw)',
    sans: 'font-family: "Px"',
    terminal: 'font-family: "Terminal", sans-serif',
    terminal_open: 'font-family: "Terminal Open", sans-serif',
    px: 'font-family: "Px", sans-serif',
    h200vh: 'height: 200vh',
    w_fit_content: 'width: fit-content',
    b_rad100p: 'border-radius: 100%',
    l50p: 'left: 50%',
    b200: 'bottom: 200px',
    t25: 'top: 25px',
    t140: 'top: 140px',
    ml_20: 'margin-left: -20px',
    b_rad100: 'border-radius: 100px',
    rotate90: 'transform: rotate(90deg)',
    rotate180: 'transform: rotate(180deg)',
    rotate270: 'transform: rotate(270deg)',
    rotate360: 'transform: rotate(360deg)',
    linear_gradient_white: 'background: linear-gradient(white, transparent)',
    inline_block: 'display: inline-block',
    bg_glass: 'backdrop-filter: blur(10px)',
    bg_transparent_white: 'background: hsla(0, 0%, 100%, 0)',
    no_select: 'user-select: none',
    mt2: 'margin-top: 2.5px',
    pv2: 'padding-top: 3px; padding-bottom: 2.5px',
    pv7: 'padding-top: 10px; padding-bottom: 8px',
  },
  media_queries: {
    __xxs: {
      query: 'max-width: 600px',
      description: 'extra extra small screens',
    },
    __xs: {
      query: 'max-width: 800px',
      description: 'extra small screens',
    },
    __s: {
      query: 'max-width: 1100px',
      description: 'small screens',
    },
  },
})

export const { Component, Div, Span } = flagify()
