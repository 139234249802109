import { Fragment, useState } from 'react'
import { Component, Div } from './flags.js'

export const Intro = ({ theme, colors }) => {
  return (
    <Navigation>
      <Hello theme={theme} colors={colors} />
      <News theme={theme} colors={colors} />
      <Menu colors={colors} />
    </Navigation>
  )
}

const Hello = ({ colors, theme }) => (
  <Window
    title="Hello"
    scrollbar_color={theme === 'light' ? colors[1] : 'var(--grey7)'}
    theme={theme}
    open={true}
    max_w50p
    bg_white={false}
    bg_glass
  >
    <Text>
      Nice to meet you! I'm Marie, a designer + programmer who loves colors,
      inputs, drawing with code, interactivity, toys, games, glitter,
      holographic, cakes... Take a look around!
    </Text>
  </Window>
)

const News = ({ colors, theme }) => (
  <Window
    open={true}
    title="Fresh news"
    theme={theme}
    scrollbar_color={theme === 'light' ? colors[1] : 'var(--grey7)'}
    max_w40p
  >
    <Text mb15>
      {news.map((n, index) => (
        <Fragment key={index}>
          <Date mt45={index}>{n.date}</Date>
          {n.text},{' '}
          <Link
            href={n.link}
            target="_blank"
            style={{
              color: theme !== 'light' ? 'black' : colors[0],
              borderColor: theme !== 'light' ? 'black' : colors[0],
            }}
          >
            check it out!
          </Link>
        </Fragment>
      ))}
    </Text>
  </Window>
)

const Menu = ({ colors }) => (
  <Window title="Menu" colors={colors}>
    <List>
      <Item mt5={false} href="#projects">
        Projects
      </Item>
      <Item href="#about">About</Item>
      <Item href="#curriculum">Curriculum</Item>
      <Item href="#skills">Skills</Item>
      <Item href="#contact">Contact</Item>
      <Item href="https://www.instagram.com/mariemalarme/" target="_blank">
        Instagram
      </Item>
    </List>
  </Window>
)

const Window = ({
  title,
  children,
  open,
  scrollbar_color,
  theme,
  ...props
}) => {
  const [is_open, set_is_open] = useState(open)
  return (
    <Wrapper bg_white={is_open} bg_glass={!is_open} {...props}>
      <Bar onClick={() => set_is_open(!is_open)}>
        <Div ph10>{title}</Div>
        <Toggle>{is_open ? '-' : '+'}</Toggle>
      </Bar>
      {is_open && (
        <Content
          style={{
            '--scrollbar-color': scrollbar_color,
            '--scrollbar-width': theme === 'light' ? '15px' : '10px',
          }}
        >
          {children}
        </Content>
      )}
    </Wrapper>
  )
}

const news = [
  {
    date: '22.09.23',
    text: 'Patternizify V1.0 release',
    link: 'https://patternizify.studiodev.xyz/',
  },
  {
    date: '13.08.23',
    text: 'Published on IG page Creative code art',
    link: 'https://www.instagram.com/p/Cv5b2Ufv9l1/?img_index=1',
  },
]

const Navigation =
  Component.bt__s.w35p__s.none__xxs.h50vh.h90vh__s.w50vw.zi5.fixed.events_none.t20.r20.flex.ai_flex_start.jc_flex_end.h_auto__s.flex_column__s.jc_flex_start__s.nav()
const Wrapper =
  Component.max_h35p__s.w100p__s.max_w100p__s.max_h100p.no_select.events_auto.fs13.ml10.ml0__s.flex.flex_column.jc_flex_start.header()
const Bar =
  Component.ws_nowrap.fs12.c_pointer.flex.jc_between.ai_center.ba.bt0__s.div()
const Toggle = Component.w25.h25.bl.flex.ai_center.jc_center.div()
const Content = Component.ofy_auto.ofx_hidden.scrollbar.ba.bt0.div()
const Text = Component.sans.fs3vw.pv20.ph25.pv10__xs.ph15__xs.div()
const List =
  Component.flex.flex_column.ai_flex_start.pv20.ph25.ofy_auto.scrollbar.div()
const Item = Component.hover_bb.sans.fs3vw.text_dec_none.black.mt5.c_pointer.a()
const Date =
  Component.ba.w_fit_content.sans.fs12.lh20.mb10.pv2.ph10.b_rad100.div()
const Link = Component.c_pointer.sans.bw2.bb.text_dec_none.a()
