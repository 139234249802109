import { Fragment, useState, useEffect } from 'react'
import { Component } from './flags.js'
import { set_url_with_params, get_image_url } from './toolbox.js'

export const Modal = ({ project, set_project }) => {
  const [open, set_open] = useState(false)
  const [immersive_view, set_immersive_view] = useState(false)

  const clear_project = () => {
    set_url_with_params('')
    set_project()
    set_open(false)
    set_immersive_view(false)
  }

  useEffect(() => {
    const handle_key = (e) => {
      if (!open && e.key !== 'Escape') return
      clear_project()
    }
    window.addEventListener('keydown', handle_key)
    return () => window.removeEventListener('keydown', handle_key)
  }, [set_project, open, clear_project])

  if (!project) return null

  const { name, visuals, demo, preview } = project
  const Demo = demo

  return (
    <Page pb35__xs={!visuals} className="fade-in">
      <Button l50 l30__xs onClick={clear_project}>
        <Icon mr20 className="arrow-icon" />
        Go back
      </Button>
      {demo && (
        <Fragment>
          <Button
            r50
            r30__xs
            onClick={() => set_immersive_view(!immersive_view)}
          >
            {immersive_view ? 'Normal' : 'Immersive'} view
            <Icon
              ml20
              className={`immersive-mode-${immersive_view ? 'off' : 'on'}-icon`}
            />
          </Button>
          <Demo immersive_view={immersive_view} />
        </Fragment>
      )}
      {preview && (
        <PreviewTop src={require(`${get_image_url(name, 'jpg', 'preview')}`)} />
      )}
      {!immersive_view && (
        <Infos project={project} open={open} set_open={set_open} />
      )}
      <Grid id="grid-visuals">
        {visuals?.map((extension, index) => {
          const Component =
            extension === 'mp4' || extension === 'mov' ? Clip : Visual

          const src = require(`${get_image_url(
            name,
            extension,
            `slide_${index + 1}`,
          )}`)

          return <Component src={src} key={index} mt150={index % 2} />
        })}
      </Grid>
    </Page>
  )
}

const Clip = ({ src, ...props }) => (
  <Video autoPlay loop muted {...props}>
    <source src={src} type="video/mp4" />
  </Video>
)

const Infos = ({ project, open, set_open }) => {
  const { fields, description, name, link, instructions, demo } = project

  return (
    <Fragment>
      <Container
        sticky={!demo}
        t140={!demo}
        events_none={demo}
        absolute={demo}
        t0={demo}
      >
        {fields && (
          <Fields>
            {fields.map((field, index) => (
              <Field key={`field-${index + 1}`}>
                {index !== 0 && <Dot>•</Dot>}
                {field}
              </Field>
            ))}
          </Fields>
        )}
        <Header>
          <Name>{name}</Name>
          {link && (
            <Link
              style={{
                userSelect: 'none',
                background: 'hsla(0, 0%, 100%, 0.25)',
              }}
              target="_blank"
              href={link}
            >
              <Icon rotate180 mr10 className="arrow-icon" /> Check it out!
            </Link>
          )}
        </Header>
        {instructions && <Instructions>{instructions}</Instructions>}
      </Container>
      {description && (
        <Description>
          <DescriptionText text={description.main} />
          {description.secondary &&
            (open ? (
              <DescriptionText text={description.secondary} mt50 />
            ) : (
              <More onClick={() => set_open(true)}>
                Read more
                <Arrow className="arrow-icon" />
              </More>
            ))}
        </Description>
      )}
    </Fragment>
  )
}

const DescriptionText = ({ text, ...props }) => {
  // check if there is a markdown link in the text with a regexp
  // (currently works only with a single link per text chunk)
  // link format looked for: [my link](https://url-of-my-link.com)
  const markdown_match = text.match(/\[([^\]]+)\]\(([^)]+)\)/)

  if (!markdown_match) return <Text {...props}>{text}</Text>

  // convert markdown to component — result of markdown match:
  // ['[my link](https://url-of-my-link.com)', 'my link', 'https://url-of-my-link.com']
  const [markdown, link_text, href] = markdown_match
  const [text_before, text_after] = text.split(markdown)

  return (
    <Text {...props}>
      {text_before}
      <DescriptionLink href={href} target="_blank" rel="noreferrer">
        {link_text}
      </DescriptionLink>
      {text_after}
    </Text>
  )
}

const Container = Component.w100p.zi1.ph35__xs.ph75.mt110__xs.mt140.div()
const More =
  Component.c_pointer.w_fit_content.o30.fs44.fs35__xs.mt30.flex.ai_center.div()
const Arrow = Component.c_pointer.w45.h45.ml25.rotate180.div()
const PreviewTop = Component.zi0.l50p.h390.t0.absolute.img()

const Page =
  Component.ofy_scroll.ofx_hidden.zi5.fixed.w100p.h100vh.bg_white.div()
const Icon = Component.c_pointer.w20.h20.mr5.div()
const Button =
  Component.zi3.fixed.t50.t30__xs.flex.ai_center.mb70.c_pointer.div()
const Header = Component.flex.flex_column__xs.ai_flex_start.mb70.mb40__xs.div()
const Name =
  Component.ofw_break.mt30.mt10__xs.fs100.fs60__xs.max_w40p.terminal_open.lh90.lh60__xs.p()
const Link =
  Component.bg_glass.text_dec_none.b_rad25.pv10.ph20.mt45.fs20.fs17__xs.ba.black.flex.ml35.ml0__xs.mt30__xs.ai_center.a()
const Fields = Component.mb20.fs12.flex.div()
const Field = Component.flex.div()
const Description = Component.relative.ph35__xs.ph75.mt70__xs.div()
const DescriptionLink = Component.black.text_dec_none.bb.b_black.bw2.sans.a()
const Instructions = Component.ws_pre_l.fs30.lh45.pb50__xs.div()
const Dot = Component.mh15.span()
const Text = Component.relative.ws_pre_l.fs44.fs35__xs.sans.div()

const Grid =
  Component.grid.ji_center.mv80.mv50__xs.mh100.mh50__xs.zi1.relative.events_none.div()
const Visual = Component.mv20.w100p.img()
const Video = Component.mv20.w100p.max_h95vh.video()
