export const skills = [
  { area: 'tech', name: 'JavaScript', amount: 24, tangibility: -90 },
  { area: 'tech', name: 'React.js', amount: 22, tangibility: -65 },
  { area: 'tech', name: 'HTML & CSS', amount: 17, tangibility: -45 },
  { area: 'tech', name: 'GraphQL', amount: 10, tangibility: -75 },
  { area: 'tech', name: 'Node.js', amount: 12, tangibility: -60 },
  { area: 'tech', name: 'Arduino', amount: 7, tangibility: 15 },
  { area: 'tech', name: 'Processing', amount: 5, tangibility: -30 },
  { area: 'tech', name: '3D printing', amount: 3, tangibility: 60 },

  { area: 'design', name: 'Visual identity', amount: 23, tangibility: 40 },
  { area: 'design', name: 'Webdesign', amount: 20, tangibility: -55 },
  { area: 'design', name: 'UX & UI', amount: 18, tangibility: 20 },
  { area: 'design', name: 'Print & Editorial', amount: 15, tangibility: 90 },
  { area: 'design', name: 'Datavisualization', amount: 10, tangibility: -40 },
  { area: 'design', name: 'Motion', amount: 7, tangibility: -15 },
  { area: 'design', name: 'Typeface', amount: 5, tangibility: 55 },
  { area: 'design', name: 'Paper engineering', amount: 2, tangibility: 80 },
]
