export const events = [
  {
    id: 8,
    year: '2022',
    day: 'Jan',
    title: 'Wix Playground Academy',
    description: 'Intensive program for emerging creatives',
    place: 'Wix Playground',
    city: 'Online',
    country: 'WEB',
    link: 'https://www.wix.com/playground/academy-nyc',
  },
  {
    id: 7,
    year: '2018',
    day: 'Nov',
    title: 'Freelancing',
    description: 'Designer & fullstack developer',
    place: 'Zone 01',
    city: 'Madeira',
    country: 'PT',
    link: 'https://www.zone01.gr/',
  },
  {
    id: 6,
    year: '2018',
    day: 'Feb',
    title: 'Professional certification',
    description: 'Fullstack JavaScript course',
    place: 'Wild Code School',
    city: 'Paris',
    country: 'FR',
    link: 'https://www.wildcodeschool.com/en-gb/',
  },
  {
    id: 5,
    year: '2017',
    day: 'Jul',
    title: 'Internship',
    description: 'Digital design',
    place: 'Bleed Studio',
    city: 'Vienna',
    country: 'AU',
    link: 'https://bleed.com/',
  },
  {
    id: 4,
    year: '2017',
    day: 'Jun',
    title: `Professional Master's degree`,
    description: 'Multimedia & interactive professions',
    place: 'La Sorbonne University',
    city: 'Paris',
    country: 'FR',
    link: 'https://formations.pantheonsorbonne.fr/fr/catalogue-des-formations/master-M/master-design-KBTI3F1I/master-parcours-metiers-du-multimedia-interactif-KBTI3Z9S.html',
  },
  {
    id: 3,
    year: '2014',
    day: 'Jun',
    title: `Master's degree`,
    description: 'Graphic design',
    place: 'LAAB Bréquigny',
    city: 'Rennes',
    country: 'FR',
    link: 'http://www.laab.fr/',
  },
  {
    id: 2,
    year: '2012',
    day: 'Jun',
    title: 'Erasmus',
    description: 'Graphic design',
    place: 'Vilnius Academy of Arts',
    city: 'Vilnius',
    country: 'LT',
    link: 'https://www.vda.lt/en/study_programs/undergraduate-ba/graphic-design-lt-en/graphic-design-undergraduate-vilnius-lt',
  },
  {
    id: 1,
    year: '2011',
    day: 'Jun',
    title: 'Bachelor',
    description: 'Visual communication',
    place: 'École Duperré Paris',
    city: 'Paris',
    country: 'FR',
    link: 'https://duperre.org/',
  },
]
