export const random = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const get_random_color = ({ alpha = 1, dark, h, s, l } = {}) => {
  const hue = h || random(0, 360)
  const saturation = s || random(60, 100)
  const luminosity = l || (dark && 40) || random(75, 85)
  return `hsla(${hue}, ${saturation}%, ${luminosity}%, ${alpha})`
}

export const get_dimensions = (i) => {
  const width = ((!(i % 4) || i % 4 === 3) && '35vw') || '65vw'
  const height = (i % 5 && '220px') || '360px'
  return { width, height }
}

export const format = (string, character = '-') =>
  string
    .toLowerCase()
    .split(' ')
    .join(character)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export const set_url_with_params = (path) => {
  const new_url = new URL(`${window.location.origin}/${path}`)
  const current_search_params = new URL(window.location).searchParams
  new_url.search = current_search_params.toString()
  window.history.pushState({}, '', new_url)
}

export const get_image_url = (name, extension, suffix) => {
  const name_snake = format(name, '_')
  const image_url = `./images/${name_snake}/${name_snake}-${suffix}.${extension}`
  return image_url
}
