import { Fragment } from 'react'
import { Component, Div } from './flags.js'

export const About = ({ colors }) => (
  <Fragment>
    <div id="about" />
    <Wrapper>
      <Banner color={colors[0]} network="Instagram" top />
      <Text>
        I'm currently freelancing between Paris & Lisbon, splitting my time
        between an exciting pedagogic software to learn Programming & personal
        side projects. I'm always open to take part in cool initiatives of any
        kind to explore & experiment unknown directions, so don't hesitate to
        reach out!
      </Text>
      <Banner color={colors[1]} network="Github" bottom />
    </Wrapper>
  </Fragment>
)

const Banner = ({ network, top, bottom, color }) => (
  <Row
    style={{ '--color': color }}
    mb30__xs={top}
    mt35__xs={bottom}
    mb50={top}
    mt55={bottom}
  >
    <Network color={color} network={network} />
  </Row>
)

const Network = ({ network, color }) => (
  <Link
    target="_blank"
    href={
      network === 'Github'
        ? 'https://github.com/MarieMalarme/'
        : 'https://www.instagram.com/mariemalarme/'
    }
  >
    {[...Array(6).keys()].map((index) => (
      <Fragment key={index}>
        <Div ml10__xs={!index} ml_20={!index}>
          Check out my {network}{' '}
        </Div>
        <Separator />
      </Fragment>
    ))}
  </Link>
)

const Wrapper = Component.zi2.sticky.t0.bg_white.pa100.pv40__xs.ph30__xs.div()
const Text = Component.sans.fs50.fs28__xs.lh60.lh35__xs.div()
const Row = Component.c_pointer.network.b_rad100.pv2.flex.jc_between.div()
const Link =
  Component.flex.ai_center.w100p.uppercase.ls1.text_dec_none.black.fs13.ws_nowrap.of_hidden.a()
const Separator = Component.separator.flex_shrink0.h1.w50.mh10.div()
