import { Fragment, useState, useEffect } from 'react'
import { Component, Div } from './flags.js'
import { format, set_url_with_params, get_image_url } from './toolbox.js'
import { get_projects, get_thumbnail_size } from './projects.js'

export const ProjectsMap = ({ projects, set_projects, ...props }) => {
  const { theme, grid, colors, set_project } = props
  const [hovered, set_hovered] = useState()

  useEffect(() => {
    const update_projects = (e) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => set_projects(get_projects), 100)
    }

    window.addEventListener('resize', update_projects)
    return () => document.removeEventListener('resize', update_projects)
  }, [set_projects])

  return (
    <Div id="projects" w100p min_h100vh>
      <Background colors={colors} theme={theme} grid={grid} />
      <Map h200vh={grid === 'random'} flex={grid === 'block'}>
        {projects.map((project, index) => (
          <Project
            key={project.name}
            index={index}
            grid={grid}
            theme={theme}
            colors={colors}
            hovered={hovered}
            project={project}
            set_project={set_project}
            set_hovered={set_hovered}
          />
        ))}
      </Map>
    </Div>
  )
}

const Background = ({ colors, theme, grid }) => {
  const [mouse, set_mouse] = useState({ x: innerWidth / 4, y: innerHeight / 4 })

  useEffect(() => {
    const update_mouse = (e) => {
      const event = e.type === 'touchmove' ? e.touches[0] : e
      set_mouse({ x: event.clientX, y: event.clientY })
    }
    document.addEventListener('mousemove', update_mouse)
    document.addEventListener('touchmove', update_mouse)
    return () => {
      document.removeEventListener('mousemove', update_mouse)
      document.removeEventListener('touchmove', update_mouse)
    }
  }, [])

  useEffect(() => {
    const init_mouse = (e) =>
      set_mouse({ x: innerWidth / 2, y: innerHeight / 2 })
    window.addEventListener('resize', init_mouse)
    return () => document.removeEventListener('resize', init_mouse)
  }, [])

  const gradient_coords = `circle at ${mouse.x}px ${mouse.y}px`
  const gradient_colors = `${colors[1]}, ${colors[0]}`
  const gradient_size =
    (grid === 'block' && 400) || (grid === 'inline' && 300) || 250
  const gradient = `radial-gradient(${gradient_coords}, ${gradient_colors}, transparent ${gradient_size}px)`

  return (
    <Words
      style={{
        background:
          ((theme === 'light' || theme === 'plain') && 'white') || colors[0],
      }}
    >
      {words.map((path, index) => (
        <Svg
          key={index}
          b0={index >= 2}
          t0={index <= 1}
          r0={index % 2}
          l0={!(index % 2)}
          viewBox="0 0 960 550"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          width={index % 2 ? mouse.x : window.innerWidth - mouse.x}
          height={index <= 1 ? mouse.y : window.innerHeight - mouse.y}
          fill={(theme === 'colors' && colors[1]) || 'hsl(0, 0%, 90%)'}
        >
          <path d={path} />
        </Svg>
      ))}
      {theme === 'light' && <Gradient style={{ background: gradient }} />}
    </Words>
  )
}

const Thumbnail = ({ project }) => {
  const name = format(project.name, '_')
  const image_url = `./images/${name}/${name}-thumbnail.jpg`
  const { top, left } = project.display.thumbnail

  return (
    <Img
      alt={name}
      className="fade-in"
      style={{ top, left }}
      height={get_thumbnail_size()}
      src={require(`${image_url}`)}
    />
  )
}

const Project = ({ project, set_project, index, theme, grid, colors }) => {
  const [is_hovered, set_is_hovered] = useState(false)
  const { name, display } = project
  const { title } = display
  const { top, right, bottom, left } = title
  const dash_name = format(name)

  useEffect(() => preload_image(name), [name])

  return (
    <Fragment>
      {is_hovered && <Thumbnail project={project} />}
      <Cell
        key={name}
        zi4={is_hovered}
        block={grid === 'block'}
        mr15={grid === 'inline'}
        mr10__xs={grid === 'inline'}
        mb15={grid === 'inline'}
        mb10__xs={grid === 'inline'}
        mb30={grid === 'block'}
        b_grey8={theme === 'light'}
        relative={grid !== 'random'}
        absolute={grid === 'random'}
        inline_block={grid === 'inline'}
        w_fit_content={grid === 'block'}
        bg_glass={grid === 'random' || theme === 'plain'}
        b_transparent={theme === 'plain' || theme === 'colors'}
        hover_b_grey8={theme === 'colors' || theme === 'plain'}
        onMouseEnter={() => set_is_hovered(true)}
        onMouseLeave={() => set_is_hovered(false)}
        className="project-title"
        onClick={() => {
          set_url_with_params(dash_name)
          set_project(project)
        }}
        style={{
          top: grid === 'random' ? top : 'auto',
          right: grid === 'random' ? right : 'auto',
          bottom: grid === 'random' ? bottom : 'auto',
          left: grid === 'random' ? left : 'auto',
          borderWidth: `max(1.5px, ${grid === 'block' ? 0.3 : 0.2}vw)`,
          fontSize: `max(30px, ${grid === 'block' ? 5 : 3}vw)`,
          padding: `max(7px, 1vw) max(15px, ${grid === 'block' ? 4 : 2}vw)`,
          '--background-color':
            (theme === 'plain' && colors[title.colors.background]) ||
            (theme === 'colors' && 'white') ||
            'none',
          '--hover-background-color':
            (theme === 'plain' && 'transparent') || 'var(--grey8)',
          '--hover-text-color':
            (theme === 'plain' && 'var(--grey8)') || 'white',
        }}
      >
        {name}
      </Cell>
    </Fragment>
  )
}

const preload_image = (name, extension, index) => {
  const image = new Image()
  image.src = require(`${get_image_url(name, 'jpg', 'thumbnail')}`)
}

const words = [
  'M17.08 542V20.8h114.58V99h22.91v52.1h22.92v78.18h22.91v78.18h22.92V411.7h22.91V307.46h22.92v-78.18h22.91V151.1H315V99h22.9V20.8h114.57V542h-68.74V151.1h-22.92v52.12H337.9v104.24H315V411.7h-22.94v78.18h-22.91V542H200.4v-52.12h-22.91V411.7h-22.92V307.46h-22.91V203.22h-22.92V151.1H85.83V542ZM507.61 542v-78.18h22.92V411.7h22.91v-78.18h22.92V281.4h22.91v-52.12h22.92v-52.12h22.91V125H668V72.92h22.91V20.8h68.75v52.12h22.91V125h22.92v52.12h22.91v52.12h22.92v52.16h22.91v52.12h22.92v78.18h22.91v52.12H943V542h-68.75v-52.12h-22.91v-52.12h-22.92v-52.12H622.19v52.12h-22.92v52.12h-22.91V542Zm297.9-234.54V281.4h-22.92v-52.12h-22.91v-52.12h-22.92V125h-22.91v52.12h-22.92v52.12H668v52.16h-22.9v26.06Z',
  'M27.86 542V20.8h267.49v26.06h24.31v26.06H344V99h24.3v130.3H344v26.06h-24.34v26.04h-24.31v26.06h-73v26.06h24.31v26.06H271v26.06h24.32v26.06h24.31v26.06H344v26.06h24.3v26.06h24.32v26.06h24.31V542h-97.27v-26.06h-24.31v-26.06H271v-26.06h-24.29v-26.06H222.4V411.7h-24.32v-26.06h-24.32v-26.06h-24.32v-26.06h-24.31v-26.06h-24.32V542ZM271 229.28v-26.06h24.32V125H271V99H100.81v130.3ZM486.09 542V20.8H559V542ZM647.19 542V20.8H939V99H720.14v130.3H939v78.18H720.14v156.34H939V542Z',
  'M14.33 524V26.8h83.15v74.58h16.63v49.72h16.63v74.58h16.63v74.58H164v99.44h16.63v-99.44h16.63v-74.58h16.63V151.1h16.63v-49.72h16.63V26.8h83.15V524h-49.89V151.1h-16.63v49.72h-16.63v99.44h-16.63v99.44h-16.63v74.58h-16.63V524h-49.89v-49.72h-16.63V399.7h-16.63v-99.44H97.48v-99.44H80.85V151.1H64.22V524ZM370.31 524v-74.58h16.63V399.7h16.63v-74.58h16.63V275.4h16.63v-49.72h16.63V176h16.63v-49.76h16.63V76.52h16.63V26.8h49.89v49.72h16.63v49.72h16.63V176h16.63v49.72h16.63v49.68h16.63v49.72H653v74.58h16.63v49.72h16.63V524h-49.87v-49.72h-16.63v-49.72h-16.63v-49.72H453.46v49.72h-16.63v49.72H420.2V524ZM586.5 300.26V275.4h-16.63v-49.72h-16.63V176h-16.63v-49.76H520V176h-16.65v49.72h-16.63v49.68h-16.63v24.86ZM726.81 524V26.8h49.89v422.62H943V524Z',
  'M28 524v-74.55h12.16v-49.7h12.16V325.2h12.17v-49.7h12.16v-49.7h12.16v-49.7H101v-49.7h12.16V76.7h12.16V27h36.49v49.7h12.16v49.7h12.13v49.7h12.17v49.7h12.16v49.7h12.16v49.7h12.16v74.55h12.16v49.7h12.17V524h-36.49v-49.7h-12.16v-49.7h-12.16v-49.7H88.81v49.7H76.65v49.7H64.49V524Zm158.1-223.65V275.5h-12.16v-49.7h-12.16v-49.7h-12.16v-49.7h-12.16v49.7h-12.17v49.7h-12.16v49.7H101v24.85ZM288.72 524V27H422.5v24.85h12.16V76.7h12.16v24.85H459V225.8h-12.18v24.85h-12.16v24.85H422.5v24.85H386v24.85h12.16v24.85h12.16v24.85h12.18v24.85h12.16v24.85h12.16v24.85H459v24.85h12.16v24.85h12.16V524h-48.66v-24.85H422.5V474.3h-12.16v-24.85h-12.16V424.6H386v-24.85h-12.15V374.9h-12.16v-24.85h-12.16V325.2h-12.16v-24.85h-12.16V524Zm121.62-298.2V201h12.16v-74.6h-12.16v-24.85h-85.13V225.8ZM517.9 524V27h60.8v74.55h12.17v49.7H603v74.55h12.16v74.55h12.16v99.4h12.16v-99.4h12.17V225.8h12.16v-74.55H676v-49.7h12.16V27H749v497h-36.51V151.25h-12.17V201h-12.16v99.4H676v99.4h-12.16v74.5h-12.16V524h-36.49v-49.7H603v-74.55h-12.13v-99.4H578.7V201h-12.16v-49.7h-12.16V524ZM793.06 524V27H939v74.55H829.54V225.8H939v74.55H829.54v149.1H939V524Z',
]

let timeout
const { innerHeight, innerWidth } = window

const Map =
  Component.flex.flex_column.ai_center.pa40.pa25__xs.pt80.pt70__xs.relative.w100p.min_h100vh.zi1.div()
const Words = Component.zi0.fixed.t0.l0.w100p.h100vh.div()
const Gradient = Component.zi1.absolute.t0.w100p.h100vh.div()
const Svg = Component.svg()

const Img = Component.none__xs.events_none.zi3.anim_opacity.fixed.img()
const Cell =
  Component.grey8.max_w100p.of_hidden.text_ellipsis.ws_nowrap.zi1.px.ba.text_center.ph35.b_rad100.c_pointer.div()
